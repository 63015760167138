import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

const router = new Router({
  routes,
});

// router.beforeEach((to, from, next) => {
//   // simple auth page guard
//   console.log('to --> ', to)
//
//
//   next();
// });

export default router;
