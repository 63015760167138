const routes = [
  {
    path: '/',
    component: () => import('../views/main'),
  },
  {
    path: '/example01',
    component: () => import('../views/example01'),
  },
  {
    path: '/example02',
    component: () => import('../views/example02'),
  },
  {
    path: '/example03',
    component: () => import('../views/example03'),
  },

];

export default routes;
